import type { EventType } from 'mitt'
import mitt from 'mitt'

interface Events extends Record<EventType, unknown> {}
const emitter = mitt<Events>()

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('bus', {
    on: emitter.on,
    off: emitter.off,
    emit: emitter.emit,
  })
})
