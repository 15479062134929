<!-- eslint-disable no-console -->
<script setup lang="ts">
import { dateZhCN, zhCN } from 'naive-ui'
import { USER_TYPE, homePath, mediaHomePath, newsHomePath, videoHomePath } from '@qcwp/common'
import { breakpoints, useTheme } from '~/common/theme'
import { HOME_SITE_META } from '~~/src/common/site'
import { useAuth } from '~~/src/store'

const route = useRoute()
// 设置默认站点信息
const { siteMeta } = useSite(HOME_SITE_META)
// ui库 全局主题设置
const { naiveTheme, naiveThemeOverrides } = useTheme()
const $bus = useNuxtApp().$bus as any
const { isDesktop } = useDevice()
// 设置版本号
setSiteVersion()
// 访问统计
useAddAccess()

// .env
// const config = useRuntimeConfig()
// console.log(config)

// Routes
// console.log('<<Routes>>:', useRouter().getRoutes())

// tabbar
const showTabbar: string[] = [homePath(), mediaHomePath(), newsHomePath(), videoHomePath()]

const authStore = useAuth()
const { USER_MEDIA_TYPE } = storeToRefs(authStore)

const loginOptions = ref<{ show: boolean; redirect: string }>({
  show: false,
  redirect: homePath(),
})
$bus.on('SHOW_LOGIN', (options: { show: boolean; redirect: string }) => {
  loginOptions.value = options
  if (loginOptions.value.show) {
    loginOptions.value.redirect = route.fullPath
    authStore.saveLoginPrePage(route.fullPath)
  }
  // console.log('loginOptions.value.redirect', loginOptions.value)
})
</script>

<template>
  <n-config-provider
    :locale="zhCN" :date-locale="dateZhCN"
    :breakpoints="breakpoints()"
    :theme="naiveTheme" :theme-overrides="naiveThemeOverrides"
    inline-theme-disabled
  >
    <utils-site-head :site-meta="siteMeta" />
    <NuxtLayout>
      <NuxtPage :keepalive="{ max: 5 }" />
    </NuxtLayout>
    <ClientOnly>
      <LayoutLoginPhone v-model="loginOptions.show" :redirect="loginOptions.redirect" />
      <template v-if="USER_MEDIA_TYPE === USER_TYPE.SELF">
        <LayoutDailyPopup />
        <LayoutDailyAdd v-if="['news-articleId', 'video-articleId', 'live-articleId'].includes(route.name as string)" />
      </template>
    </ClientOnly>
    <template v-if="showTabbar.includes(route.path) && !isDesktop">
      <layout-tabbar class="block md:hidden fixed bottom-0 left-0 right-0 w-full z-99" />
      <div class="tabbar-placeholder h-12 md:hidden" />
    </template>
  </n-config-provider>
</template>

<style lang="scss">
html {
  font-size: max(1.0526vw, 16px);
}

.image-error {
  position: relative;
}

img.image-error::before {
  --uno: content-empty absolute top-0 left-0 right-0 bottom-0;
  --uno: bg-slate-1;
}

img.image-error::after {
  --uno: content-empty absolute top-1/2 left-1/2 -translate-1/2 right-0 bottom-0;
  --uno: bg-slate-3 i-ic:baseline-broken-image;
}

#__nuxt,
html,
body {
  margin: 0;
  padding: 0;
  word-break: break-all;
}

body {
  --uno: text-3.5;
  --van-font-size-xs: 0.625rem;
  --van-font-size-sm: 0.75rem;
  --van-font-size-md: 0.875rem;
  --van-font-size-lg: 1rem;
}

#nprogress .bar {
  background-color: var(--color-primary);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

@media (max-width: 640px) {
  ::-webkit-scrollbar {
    display: none;
  }

  #nprogress .bar {
    top: 3rem;
  }
}

.van-toast {
  background: var(--van-toast-background-color)!important;
}

.van-toast__icon {
  font-size: var(--van-toast-icon-size)!important;
}

.n-carousel .n-carousel__arrow {
  background-color: rgb(0 0 0 / 30%);

  &:hover {
    background-color: rgb(0 0 0 / 20%);
  }
}

a:focus:focus-visible,
button:focus:focus-visible {
  --uno: outline outline-2 outline-blue-5;
}

:root:root {
  --vant-font-size-xs: 0.25rem;
  --vant-font-size-sm: 0.3rem;
  --vant-font-size-md: 0.75rem;
}
</style>
