export default defineNuxtPlugin(async (_nuxtApp) => {
  const isDev = useCookie('isDev')
  const { isMobileOrTablet } = useDevice()
  if (isMobileOrTablet && isDev.value) {
    await import('https://unpkg.com/vconsole@latest/dist/vconsole.min.js' as any)
    const { VConsole } = (window || {}) as any
    if (!VConsole)
      return console.warn('vConsole is not loaded')
    _nuxtApp.vueApp.provide('vConsole', new VConsole())
  }
})
