<script setup lang='ts'>
import { imageTransform } from '@qcwp/common'
const props = withDefaults(defineProps<{
  src: string
  index?: number
  imgList?: string[]
  tools?: boolean
  isShowArrow?: boolean
}>(), {
  src: '',
  index: 0,
  imgList: () => [],
  tools: true,
  isShowArrow: true,
})
const emits = defineEmits({
  replace: (index: number) => true,
  prev: () => true,
  next: () => true,
})

function replace() {

}
</script>

<template>
  <div class="relative">
    <base-proportion-img :src="imageTransform(props.src, { format: 'webp' })" :proportion="56.25" />
    <div v-if="tools" class="absolute absolute-0">
      <div class="bg-slate-9 bg-opacity-70 rounded-sm px-3 text-3 py-0.75 text-white inline-flex items-center justify-center absolute left-1/2 -translate-x-1/2 bottom-2 ">
        <span @click="replace">替换</span>
      </div>
      <template v-if="isShowArrow">
        <span class="tool-arrow left-3">
          <i class="i-ic:outline-keyboard-arrow-left" />
        </span>
        <span class="tool-arrow right-3">
          <i class="i-ic:outline-keyboard-arrow-right" />
        </span>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tool-arrow {
  --uno: bg-slate-9 bg-opacity-70 rounded-full text-white text-6 w-10 h-10 grid place-content-center  absolute top-1/2 -translate-y-1/2;
}
</style>
