import type { FieldRule } from 'vant'
import { validatorPhone } from '@qcwp/utils'
import type { Ref } from 'vue'

/**
 * 账号校验规则
 * @param message string
 * @returns
 */
export const baseRule = (message = '请填写用户名'): FieldRule => ({ required: true, message })

/**
 * 账号校验规则
 * @param message string
 * @returns
 */
export const phoneBaseRule = (message = '请输入正确手机号'): FieldRule => ({ validator: validatorPhone, message })

/**
 * 账号校验规则
 * @param message string
 * @returns
 */
export function comparePasswordValidator(data: Ref, keys: [string, string] = ['password', 'rePassword'], message = '两次密码不一致') {
  const validator = () => data.value[keys[0]] && data.value[keys[1]] && data.value[keys[0]] === data.value[keys[1]]
  return { validator, message, trigger: 'onBlur' }
}
