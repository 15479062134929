import { VERSION } from '@qcwp/common'
import { isClient } from '@qcwp/utils'
import type { SiteConfig } from '../common/site'
import { DEFAULT_DESCRIPTION, DEFAULT_KEYWORDS, DEFAULT_TITLE, HOME_SITE_META } from '../common/site'

export function useSite(config: Partial<SiteConfig> = HOME_SITE_META) {
  // const siteMeta = useState('siteMeta', () => ({
  //   title: config?.title || DEFAULT_TITLE,
  //   description: config?.description || DEFAULT_DESCRIPTION,
  //   keywords: config?.keywords || DEFAULT_KEYWORDS,
  // }))

  const siteMeta = ref({
    title: config?.title || DEFAULT_TITLE,
    description: config?.description || DEFAULT_DESCRIPTION,
    keywords: config?.keywords || DEFAULT_KEYWORDS,
  })

  siteMeta.value.title = config?.title || DEFAULT_TITLE
  siteMeta.value.description = config?.description || DEFAULT_DESCRIPTION
  siteMeta.value.keywords = config?.keywords || DEFAULT_KEYWORDS

  function changeMeta(config: Partial<SiteConfig>) {
    for (const key in config) {
      if (config[key as keyof SiteConfig])
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        siteMeta.value[key] = config[key]
    }
  }
  // 如果只是静态不变
  try {
    useHead(computed(() => ({
      title: siteMeta.value.title,
      metaMeta: [
        {
          name: 'description',
          content: siteMeta.value.description?.slice(0, 200),
        },
        {
          name: 'keywords',
          content: siteMeta.value.keywords.join(','),
        },
      ],
    })))
  }
  catch (err) {
    console.error('useHead error', err)
  }

  // 初始化，useState不一定能初始化
  changeMeta(config)

  return {
    siteMeta,
    changeMeta,
  }
}

// TODO: Determine version when js loading error
export function setSiteVersion() {
  if (!isClient)
    return
  const config = useRuntimeConfig()
  config.public.version = VERSION.WEB
  const version = useLocalStorage('VERSION_WEB', VERSION.WEB)
  // TODO some handle
  if (version.value !== VERSION.WEB) {
    // eslint-disable-next-line no-console
    console.log('<<WEB:site update>>')
    localStorage.clear()
    version.value = VERSION.WEB
    // vantDialog({
    //   title: '版本已更新',
    //   message: '点击确认重新加载页面以保证访问最新版。或手动重新加载(Ctrl+F5)',
    //   theme: 'round-button',
    // }).then(() => {
    //   // 加时间戳保证重新加载html
    //   window.location.href = `${window.location.href.split('?')[0]}?${Date.now()}`
    // })
  }
}
