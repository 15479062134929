<script setup lang='ts'>
import { articleDetailPath, imageTransform } from '@qcwp/common'
import type { DailyEssenceArticleDataRefVO } from '~~/src/server/modules/article/type'
import { useDailyStore } from '~~/src/store'
const props = withDefaults(defineProps<{
  title: string
  item: DailyEssenceArticleDataRefVO
}>(), {
  title: '',
})
const emits = defineEmits({
  'update:title': (_title: string) => true,
})

const imageWidth = 250
const imageHeight = Math.floor(imageWidth * 0.5625)

const dailyStore = useDailyStore()

const isEdit = ref(false)
const oldTitle = ref('')
function edit(edit = true) {
  isEdit.value = edit
  if (!edit)
    emits('update:title', oldTitle.value)
  else
    oldTitle.value = props.title
}

async function save() {
  await dailyStore.updateHandler(props.item)
  isEdit.value = false
}
</script>

<template>
  <div>
    <base-textarea
      :model-value="title"
      :disabled="!isEdit"
      :class="[!isEdit ? '' : 'border-1 border-slate-3']"
      autofocus
      class="px-2 py-0.5 pt-0.75 text-3.75 font-bold rounded-md"
      @update:model-value="(value:string) => emits('update:title', value)"
      @click="edit(true)"
    />
    <div class="flex items-center justify-center mt-2">
      <layout-daily-tool-img
        v-for="v, i in props.item?.dataRefImgs" :key="i"
        class="mx-1 w-1/3"
        :is-show-arrow="false"
        :tools="false"
        :src="imageTransform(v, { width: imageWidth, height: imageHeight })"
      />
    </div>
    <layout-daily-media :data-ref-type="item.dataRefType" :media-list="item.mediaUserVOList" :look-count="Number(item.look)" />
    <div class="flex items-center justify-center mt-3 gap-3">
      <template v-if="!isEdit">
        <nuxt-link :to="articleDetailPath(item.articleType, { id: item.dataRefId })" class="px-4 py-1 rounded-md bg-blue-5 text-white">
          查看详情
        </nuxt-link>
        <button
          class="px-4 py-1 rounded-md bg-red-5 text-white"
          @click="dailyStore.removeHandler({
            dataRefId: item.dataRefId,
            articleType: item.articleType,
            essenceColumn: item.essenceColumn,
          })"
        >
          删除
        </button>
        <button
          class="px-4 py-1 rounded-md bg-green-5 text-white"
          @click="edit(true)"
        >
          编辑
        </button>
      </template>

      <template v-else>
        <button
          class="px-4 py-1 rounded-md bg-cyan-5 text-white"
          @click="save()"
        >
          保存
        </button>
        <button
          class="px-4 py-1 rounded-md bg-slate-4 text-white"
          @click="edit(false)"
        >
          取消
        </button>
      </template>
    </div>
  </div>
</template>

<style lang='scss' scoped>

</style>
