<script setup lang='ts'>
import { FILE_PRE_HOST, imageTransform } from '@qcwp/common'
import { transferStr } from '@qcwp/utils'
import { useDailyStore } from '~~/src/store'
import type { DailyEssenceArticleDataRefVO, DailyEssenceColumn } from '~~/src/server/modules/article/type'
import { LogicDailyHandler } from '~~/src/logic/article/daily'
import { catchUploadFile } from '~~/src/server/modules/base'
import { FileType } from '~~/src/server/modules/base/types'

const { isMobileOrTablet } = useDevice()
const placement = isMobileOrTablet ? 'bottom' : 'right'

const dailyStore = useDailyStore()
const { currentArticle, addOption, currentEssence, essenceId, currentMaxSort } = storeToRefs(dailyStore)
const { addDataHandler } = LogicDailyHandler()

const tips: Record<DailyEssenceColumn, string> = {
  1: '今日精华',
  2: '今日导读',
  3: '测评试驾',
}
function select(v: string) {
  const { maxImages = 3 } = addOption.value
  if (!addOption.value.selectImages.includes(v)) {
    if (addOption.value.selectImages.length >= maxImages)
      return vantToast(`最多选择${maxImages}张图片`)
    else
      addOption.value.selectImages.push(v)
  }
  else {
    addOption.value.selectImages.splice(addOption.value.selectImages.indexOf(v), 1)
  }
}
async function transformImages() {
  const needTransformImages = new Map<number, string>()
  addOption.value.selectImages.forEach((v, i) => {
    if (v && v.startsWith('http') && !v.match(FILE_PRE_HOST))
      needTransformImages.set(i, v)
  })
  if (needTransformImages.size > 0) {
    const { setToast, showToast } = useToast(true, 'dialog', { title: '图片转存失败', loading: '图片转存中...', success: '图片转存成功' })
    const promises = []
    for (const [i, v] of needTransformImages)
      promises[i] = catchUploadFile({ code: FileType.ARTICLE, file: transferStr(v) })

    try {
      const result = await Promise.all(promises)
      for (const [i] of needTransformImages)
        addOption.value.selectImages[i] = result[i].data

      showToast(true)
    }
    catch (e) {
      let text = '第'
      for (const [i] of needTransformImages)
        text += `${i + 1}、`
      text = `${text.slice(0, -1)}张图片`
      setToast(`${getErrorMsg(e)},${text}`, 'fail')
      showToast()
    }
  }
}
async function save() {
  if (!currentArticle.value)
    return

  if (addOption.value.selectImages.length < 1) {
    return vantDialog({
      title: '提示',
      message: '请选择图片',
    })
  }

  await transformImages()
  const data: DailyEssenceArticleDataRefVO = {
    ...currentArticle.value,
    dataRefImgs: addOption.value.selectImages,
    essenceColumn: addOption.value.essenceColumn,
    sort: `${currentMaxSort.value + 1}`,
  }
  await addDataHandler(data, essenceId.value)
  currentEssence.value?.articles.push(data)
  addOption.value.show = false
  dailyStore.updateDailyHandler()
}
onDeactivated(() => {
  addOption.value.show = false
})
</script>

<template>
  <n-drawer v-model:show="addOption.show" :width="400" height="80vh" :placement="placement">
    <n-drawer-content :title="`添加到${tips[addOption.essenceColumn]}`" closable>
      <div v-if="currentArticle" class="pb-20 relative">
        <base-textarea
          v-if="addOption.essenceColumn === '2'"
          v-model="currentArticle.dataRefTitle"
          class="px-2 py-0.5 pt-0.75 border-1 border-slate-3 text-3.75 font-bold rounded-md"
        />
        <div>
          <div v-for="v, i in currentArticle.dataRefImgs" :key="i" class="relative my-3" @click="select(v)">
            <base-proportion-img
              :src="imageTransform(v, { width: 620, height: 356, format: 'webp' })"
              :proportion="56.25"
            />
            <div v-if="addOption.selectImages.includes(v)" class="absolute top-1 left-1 w-5 h-5 rounded-full bg-blue-5 text-white grid place-content-center">
              {{ addOption.selectImages.indexOf(v) + 1 }}
            </div>
          </div>
        </div>
        <button class="w-full text-center bg-blue-5 text-white rounded-md py-2 sticky bottom-0" @click="save">
          完成并添加
        </button>
      </div>
    </n-drawer-content>
  </n-drawer>
</template>

<style lang='scss' scoped>
.daily-button {
  --uno: bg-white shadow-lg border-1 border-gray-1 rounded-full flex flex-col items-center justify-center w-10 h-10 cursor-pointer;
  --uno: fixed z-99 bottom-58 right-3 bg-blue-5 text-white;
  --uno: md-w-15 md-h-15 md-text-4.5 md-right-5 md-bottom-20;
}

.title {
  --uno: text-lg font-bold mb-sm text-center bg-slate-7 text-white rounded-sm py-1;
}
</style>
