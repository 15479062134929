import { isClient } from '@qcwp/utils'
import { useAbort } from '~~/src/server/request/abort'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.$useAbort = useAbort()
  const { pendingRequest, abortPrePageKeys } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
  addRouteMiddleware('global-abort', (_to, _from) => {
    // console.log(_to, _from)
    // console.log('global-abort', pendingRequest.size)
    // clear abort request
    const extendsRoute = ['login', 'login-register', 'login-forget', 'car-testdrive']
    const isExtendsPage = extendsRoute.includes(_to.name as string) || extendsRoute.includes(_from.name as string)
    // console.log('isLoginPage', isExtendsPage, _to.name, _from.name)
    if (pendingRequest.size > 0 && !isExtendsPage) {
      pendingRequest.forEach((value: any, key: any) => {
        (value.controller as AbortController).abort(isClient
          ? new DOMException('页面切换终止请求', 'AbortPrePageError')
          : new Error('页面切换终止请求'))
        abortPrePageKeys.push(key)
      })
      pendingRequest.clear()
    }
  }, { global: true })
})
