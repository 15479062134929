// import {
//   Button,
//   Cell,
//   CellGroup,
//   Col,
//   ConfigProvider,
//   CountDown,
//   Divider,
//   Field,
//   Form,
//   Image,
//   Lazyload,
//   List,
//   Loading,
//   NavBar,
//   NoticeBar,
//   Pagination,
//   Popup,
//   ShareSheet,
//   Sidebar,
//   SidebarItem,
//   Tab,
//   Tabbar,
//   TabbarItem,
//   Tabs,
// } from 'vant/es'
import '@vant/touch-emulator'
// 目前在 nuxt 中无法按需引入样式，因此采用手动引入的方式
import 'vant/lib/index.css'

export default defineNuxtPlugin((_nuxtApp) => {
  // _nuxtApp.vueApp.use(ConfigProvider)
  //   .use(Button)
  //   .use(Col)
  //   .use(Cell)
  //   .use(CellGroup)
  //   .use(Divider)
  //   .use(Field)
  //   .use(Form)
  //   .use(Image)
  //   .use(Loading)
  //   .use(List)
  //   .use(Lazyload) // 可配置额外选项 http://vant-contrib.gitee.io/vant/#/zh-CN/lazyload
  //   .use(CountDown)
  //   .use(NavBar)
  //   .use(NoticeBar)
  //   .use(Pagination)
  //   .use(Popup)
  //   .use(ShareSheet)
  //   .use(Sidebar)
  //   .use(SidebarItem)
  //   .use(Tab)
  //   .use(Tabbar)
  //   .use(TabbarItem)
  //   .use(Tabs)
})
