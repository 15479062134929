<script setup lang='ts'>
import { useTabbarComponent } from './hooks'

const {
  popupShow,
  active,
  beforeChange,
  initActive,
} = useTabbarComponent()

let stop = watchEffect(initActive)
onActivated(() => {
  stop?.()
  stop = watchEffect(initActive)
})
onDeactivated(() => stop?.())
onUnmounted(() => stop?.())
</script>

<template>
  <div md:hidden>
    <van-config-provider :theme-vars="{ tabbarBackgroundColor: '#f7f8fc', tabbarItemActiveBackgroundColor: '#f7f8fc' }">
      <van-tabbar v-model="active" z-index="99" placeholder safe-area-inset-bottom inactive-color="#222" active-color="#3b82f6" :before-change="beforeChange">
        <van-tabbar-item name="home">
          <!-- #icon="props" -->
          <template #icon>
            <i i-ant-design:home-filled />
          </template>
          <span>首页</span>
        </van-tabbar-item>
        <van-tabbar-item name="media">
          <template #icon>
            <i i-fa-solid:user-edit />
          </template>
          网评号
        </van-tabbar-item>
        <van-tabbar-item name="news">
          <template #icon>
            <i i-mingcute:news-fill />
          </template>
          <span>资讯</span>
        </van-tabbar-item>
        <van-tabbar-item name="video">
          <template #icon>
            <i i-carbon:video-filled />
          </template>
          <span>视频</span>
        </van-tabbar-item>
        <!-- <van-tabbar-item name="car">
          <template #icon>
            <i i-fa-solid:car />
          </template>
          汽车
        </van-tabbar-item> -->
        <!-- <van-tabbar-item name="more">
          <template #icon>
            <i i-icon-park-solid:more-four />
          </template>
          更多
        </van-tabbar-item> -->
        <van-tabbar-item name="user">
          <template #icon>
            <i i-mingcute:user-3-fill />
          </template>
          <span>个人中心</span>
        </van-tabbar-item>
      </van-tabbar>
    </van-config-provider>

    <!-- <lazy-layout-tabbar-popup v-model:show="popupShow" /> -->
  </div>
</template>
