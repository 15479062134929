import { default as _91_46_46_46slug_93LP0szJkI2OMeta } from "D:/word/qcwp-vue/packages/web/src/pages/[...slug].vue?macro=true";
import { default as _4042hu972HwufMeta } from "D:/word/qcwp-vue/packages/web/src/pages/404.vue?macro=true";
import { default as _5002yjpFnZdE2Meta } from "D:/word/qcwp-vue/packages/web/src/pages/500.vue?macro=true";
import { default as _91typeId_93oEchzN7qh7Meta } from "D:/word/qcwp-vue/packages/web/src/pages/article/articleType/[typeId].vue?macro=true";
import { default as _91authorId_93AYqPar0chYMeta } from "D:/word/qcwp-vue/packages/web/src/pages/article/authorArticle/[authorId].vue?macro=true";
import { default as dailyPTQWg3f5vgMeta } from "D:/word/qcwp-vue/packages/web/src/pages/article/daily.vue?macro=true";
import { default as mediaIndexOcEtGgH0nwMeta } from "D:/word/qcwp-vue/packages/web/src/pages/article/mediaIndex.vue?macro=true";
import { default as index1p2v3g3Gi1Meta } from "D:/word/qcwp-vue/packages/web/src/pages/car/applyList/index.vue?macro=true";
import { default as indexL0ws6OdwqnMeta } from "D:/word/qcwp-vue/packages/web/src/pages/car/carTuku/index.vue?macro=true";
import { default as indextFdW3febDhMeta } from "D:/word/qcwp-vue/packages/web/src/pages/car/carTypeImg/index.vue?macro=true";
import { default as index3rs37ftW7BMeta } from "D:/word/qcwp-vue/packages/web/src/pages/car/duibi/index.vue?macro=true";
import { default as _91seriesId_93m0LQx5WwRWMeta } from "D:/word/qcwp-vue/packages/web/src/pages/car/seriesImg/[seriesId].vue?macro=true";
import { default as _91_91id_93_93NwgYZ9geJuMeta } from "D:/word/qcwp-vue/packages/web/src/pages/car/testDrive/[[id]].vue?macro=true";
import { default as _91_91venderId_93_931W7FV4C0Z1Meta } from "D:/word/qcwp-vue/packages/web/src/pages/car/zhaoche/[brandId]/[[venderId]].vue?macro=true";
import { default as indexzG84JdmDaaMeta } from "D:/word/qcwp-vue/packages/web/src/pages/car/zhaoche/index.vue?macro=true";
import { default as _91_91paramsString_93_9325FhHLs51bMeta } from "D:/word/qcwp-vue/packages/web/src/pages/car/zhaocheZh/[[paramsString]].vue?macro=true";
import { default as cuxiaoAkf68XgsiSMeta } from "D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/cuxiao.vue?macro=true";
import { default as _91modelId_93Y3G5bmF84OMeta } from "D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/dealerCarDetail/[seriesId]/[modelId].vue?macro=true";
import { default as indexrxD0ICBHUiMeta } from "D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/dealerCarDetail/[seriesId]/index.vue?macro=true";
import { default as indexe54acCKU6rMeta } from "D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/index.vue?macro=true";
import { default as introduce7MH3L4JmBiMeta } from "D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/introduce.vue?macro=true";
import { default as modelGyb3jEx6qzMeta } from "D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/model.vue?macro=true";
import { default as index0fHC2M91VfMeta } from "D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/news/index.vue?macro=true";
import { default as xjsjlipvFXqqyLMeta } from "D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/xjsj.vue?macro=true";
import { default as _91dealerId_93I9JIX2Z76wMeta } from "D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId].vue?macro=true";
import { default as seriesfPNvkKnjEMMeta } from "D:/word/qcwp-vue/packages/web/src/pages/doArticle_search/components/series.vue?macro=true";
import { default as indexfjhOcQPJIvMeta } from "D:/word/qcwp-vue/packages/web/src/pages/doArticle_search/index.vue?macro=true";
import { default as _91_46_46_46slug_93RP2TB7gc1VMeta } from "D:/word/qcwp-vue/packages/web/src/pages/docs/[...slug].vue?macro=true";
import { default as _91_91paramsString_93_93HXwgVeoUz6Meta } from "D:/word/qcwp-vue/packages/web/src/pages/findDealer/[[paramsString]].vue?macro=true";
import { default as linkUlsvuuvQ5XMeta } from "D:/word/qcwp-vue/packages/web/src/pages/help/link.vue?macro=true";
import { default as indexD1liWwjAwSMeta } from "D:/word/qcwp-vue/packages/web/src/pages/index/index.vue?macro=true";
import { default as _91_91paramsString_93_93A8jpe9vR4uMeta } from "D:/word/qcwp-vue/packages/web/src/pages/koubei/[[paramsString]].vue?macro=true";
import { default as indexzRMDbYCfisMeta } from "D:/word/qcwp-vue/packages/web/src/pages/koubei/publishPraise/index.vue?macro=true";
import { default as _91articleId_93oNc7MbZmb5Meta } from "D:/word/qcwp-vue/packages/web/src/pages/live/[articleId].vue?macro=true";
import { default as indexTGQJtd3plzMeta } from "D:/word/qcwp-vue/packages/web/src/pages/live/index.vue?macro=true";
import { default as forget6oDrFCcRc7Meta } from "D:/word/qcwp-vue/packages/web/src/pages/login/forget.vue?macro=true";
import { default as indexIaZE2rsRo2Meta } from "D:/word/qcwp-vue/packages/web/src/pages/login/index.vue?macro=true";
import { default as registertfRZObhikbMeta } from "D:/word/qcwp-vue/packages/web/src/pages/login/register.vue?macro=true";
import { default as _91articleId_93yE6BINeKedMeta } from "D:/word/qcwp-vue/packages/web/src/pages/news/[articleId].vue?macro=true";
import { default as index4JMDg8PppRMeta } from "D:/word/qcwp-vue/packages/web/src/pages/news/index.vue?macro=true";
import { default as ztChannelYGMG3w51FmMeta } from "D:/word/qcwp-vue/packages/web/src/pages/news/ztChannel.vue?macro=true";
import { default as _91_91typeId_93_93KGDlPsXreoMeta } from "D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/article/[[typeId]].vue?macro=true";
import { default as indexKsOkwUMVqpMeta } from "D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/index.vue?macro=true";
import { default as _91modelId_93BJXZTFI5MxMeta } from "D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/parameter/[modelId].vue?macro=true";
import { default as indexhO4CfHSMfFMeta } from "D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/parameter/index.vue?macro=true";
import { default as indexUkYPvfqLygMeta } from "D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/praise/index.vue?macro=true";
import { default as _91_91paramsString_93_930c0CvklBXgMeta } from "D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/price/[[paramsString]].vue?macro=true";
import { default as _91modelId_93nWAiG6EeieMeta } from "D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/price/inquiry/[modelId].vue?macro=true";
import { default as indexeTn9VTJmcaMeta } from "D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/video/index.vue?macro=true";
import { default as _91seriesId_93VBQc0b4EuZMeta } from "D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId].vue?macro=true";
import { default as _91articleId_93TpUp9v8Pe3Meta } from "D:/word/qcwp-vue/packages/web/src/pages/video/[articleId].vue?macro=true";
import { default as indexLxngUw5WvMMeta } from "D:/word/qcwp-vue/packages/web/src/pages/video/index.vue?macro=true";
import { default as listizirRf9lVaMeta } from "D:/word/qcwp-vue/packages/web/src/pages/video/list.vue?macro=true";
import { default as _91topicId_93UCE2xQInLBMeta } from "D:/word/qcwp-vue/packages/web/src/pages/zhuanti/[topicId].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93LP0szJkI2OMeta?.name ?? "slug",
    path: _91_46_46_46slug_93LP0szJkI2OMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93LP0szJkI2OMeta || {},
    alias: _91_46_46_46slug_93LP0szJkI2OMeta?.alias || [],
    redirect: _91_46_46_46slug_93LP0szJkI2OMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _4042hu972HwufMeta?.name ?? "404",
    path: _4042hu972HwufMeta?.path ?? "/404",
    meta: _4042hu972HwufMeta || {},
    alias: _4042hu972HwufMeta?.alias || [],
    redirect: _4042hu972HwufMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _5002yjpFnZdE2Meta?.name ?? "500",
    path: _5002yjpFnZdE2Meta?.path ?? "/500",
    meta: _5002yjpFnZdE2Meta || {},
    alias: _5002yjpFnZdE2Meta?.alias || [],
    redirect: _5002yjpFnZdE2Meta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: _91typeId_93oEchzN7qh7Meta?.name ?? "article-articleType-typeId",
    path: _91typeId_93oEchzN7qh7Meta?.path ?? "/article/articleType/:typeId()",
    meta: _91typeId_93oEchzN7qh7Meta || {},
    alias: _91typeId_93oEchzN7qh7Meta?.alias || [],
    redirect: _91typeId_93oEchzN7qh7Meta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/article/articleType/[typeId].vue").then(m => m.default || m)
  },
  {
    name: _91authorId_93AYqPar0chYMeta?.name ?? "article-authorArticle-authorId",
    path: _91authorId_93AYqPar0chYMeta?.path ?? "/article/authorArticle/:authorId()",
    meta: _91authorId_93AYqPar0chYMeta || {},
    alias: _91authorId_93AYqPar0chYMeta?.alias || [],
    redirect: _91authorId_93AYqPar0chYMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/article/authorArticle/[authorId].vue").then(m => m.default || m)
  },
  {
    name: dailyPTQWg3f5vgMeta?.name ?? "article-daily",
    path: dailyPTQWg3f5vgMeta?.path ?? "/article/daily",
    meta: dailyPTQWg3f5vgMeta || {},
    alias: dailyPTQWg3f5vgMeta?.alias || [],
    redirect: dailyPTQWg3f5vgMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/article/daily.vue").then(m => m.default || m)
  },
  {
    name: mediaIndexOcEtGgH0nwMeta?.name ?? "article-mediaIndex",
    path: mediaIndexOcEtGgH0nwMeta?.path ?? "/article/mediaIndex",
    meta: mediaIndexOcEtGgH0nwMeta || {},
    alias: mediaIndexOcEtGgH0nwMeta?.alias || [],
    redirect: mediaIndexOcEtGgH0nwMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/article/mediaIndex.vue").then(m => m.default || m)
  },
  {
    name: index1p2v3g3Gi1Meta?.name ?? "car-applyList",
    path: index1p2v3g3Gi1Meta?.path ?? "/car/applyList",
    meta: index1p2v3g3Gi1Meta || {},
    alias: index1p2v3g3Gi1Meta?.alias || [],
    redirect: index1p2v3g3Gi1Meta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/car/applyList/index.vue").then(m => m.default || m)
  },
  {
    name: indexL0ws6OdwqnMeta?.name ?? "car-carTuku",
    path: indexL0ws6OdwqnMeta?.path ?? "/car/carTuku",
    meta: indexL0ws6OdwqnMeta || {},
    alias: indexL0ws6OdwqnMeta?.alias || [],
    redirect: indexL0ws6OdwqnMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/car/carTuku/index.vue").then(m => m.default || m)
  },
  {
    name: indextFdW3febDhMeta?.name ?? "car-carTypeImg",
    path: indextFdW3febDhMeta?.path ?? "/car/carTypeImg",
    meta: indextFdW3febDhMeta || {},
    alias: indextFdW3febDhMeta?.alias || [],
    redirect: indextFdW3febDhMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/car/carTypeImg/index.vue").then(m => m.default || m)
  },
  {
    name: index3rs37ftW7BMeta?.name ?? "car-duibi",
    path: index3rs37ftW7BMeta?.path ?? "/car/duibi",
    meta: index3rs37ftW7BMeta || {},
    alias: index3rs37ftW7BMeta?.alias || [],
    redirect: index3rs37ftW7BMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/car/duibi/index.vue").then(m => m.default || m)
  },
  {
    name: _91seriesId_93m0LQx5WwRWMeta?.name ?? "car-seriesImg-seriesId",
    path: _91seriesId_93m0LQx5WwRWMeta?.path ?? "/car/seriesImg/:seriesId()",
    meta: _91seriesId_93m0LQx5WwRWMeta || {},
    alias: _91seriesId_93m0LQx5WwRWMeta?.alias || [],
    redirect: _91seriesId_93m0LQx5WwRWMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/car/seriesImg/[seriesId].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93NwgYZ9geJuMeta?.name ?? "car-testDrive-id",
    path: _91_91id_93_93NwgYZ9geJuMeta?.path ?? "/car/testDrive/:id?",
    meta: _91_91id_93_93NwgYZ9geJuMeta || {},
    alias: _91_91id_93_93NwgYZ9geJuMeta?.alias || [],
    redirect: _91_91id_93_93NwgYZ9geJuMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/car/testDrive/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91venderId_93_931W7FV4C0Z1Meta?.name ?? "car-zhaoche-brandId-venderId",
    path: _91_91venderId_93_931W7FV4C0Z1Meta?.path ?? "/car/zhaoche/:brandId()/:venderId?",
    meta: _91_91venderId_93_931W7FV4C0Z1Meta || {},
    alias: _91_91venderId_93_931W7FV4C0Z1Meta?.alias || [],
    redirect: _91_91venderId_93_931W7FV4C0Z1Meta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/car/zhaoche/[brandId]/[[venderId]].vue").then(m => m.default || m)
  },
  {
    name: indexzG84JdmDaaMeta?.name ?? "car-zhaoche",
    path: indexzG84JdmDaaMeta?.path ?? "/car/zhaoche",
    meta: indexzG84JdmDaaMeta || {},
    alias: indexzG84JdmDaaMeta?.alias || [],
    redirect: indexzG84JdmDaaMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/car/zhaoche/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91paramsString_93_9325FhHLs51bMeta?.name ?? "car-zhaocheZh-paramsString",
    path: _91_91paramsString_93_9325FhHLs51bMeta?.path ?? "/car/zhaocheZh/:paramsString?",
    meta: _91_91paramsString_93_9325FhHLs51bMeta || {},
    alias: _91_91paramsString_93_9325FhHLs51bMeta?.alias || [],
    redirect: _91_91paramsString_93_9325FhHLs51bMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/car/zhaocheZh/[[paramsString]].vue").then(m => m.default || m)
  },
  {
    path: _91dealerId_93I9JIX2Z76wMeta?.path ?? "/dealer/:dealerId()",
    children: [
  {
    name: cuxiaoAkf68XgsiSMeta?.name ?? "dealer-dealerId-cuxiao",
    path: cuxiaoAkf68XgsiSMeta?.path ?? "cuxiao",
    meta: cuxiaoAkf68XgsiSMeta || {},
    alias: cuxiaoAkf68XgsiSMeta?.alias || [],
    redirect: cuxiaoAkf68XgsiSMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/cuxiao.vue").then(m => m.default || m)
  },
  {
    name: _91modelId_93Y3G5bmF84OMeta?.name ?? "dealer-dealerId-dealerCarDetail-seriesId-modelId",
    path: _91modelId_93Y3G5bmF84OMeta?.path ?? "dealerCarDetail/:seriesId()/:modelId()",
    meta: _91modelId_93Y3G5bmF84OMeta || {},
    alias: _91modelId_93Y3G5bmF84OMeta?.alias || [],
    redirect: _91modelId_93Y3G5bmF84OMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/dealerCarDetail/[seriesId]/[modelId].vue").then(m => m.default || m)
  },
  {
    name: indexrxD0ICBHUiMeta?.name ?? "dealer-dealerId-dealerCarDetail-seriesId",
    path: indexrxD0ICBHUiMeta?.path ?? "dealerCarDetail/:seriesId()",
    meta: indexrxD0ICBHUiMeta || {},
    alias: indexrxD0ICBHUiMeta?.alias || [],
    redirect: indexrxD0ICBHUiMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/dealerCarDetail/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexe54acCKU6rMeta?.name ?? "dealer-dealerId",
    path: indexe54acCKU6rMeta?.path ?? "",
    meta: indexe54acCKU6rMeta || {},
    alias: indexe54acCKU6rMeta?.alias || [],
    redirect: indexe54acCKU6rMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/index.vue").then(m => m.default || m)
  },
  {
    name: introduce7MH3L4JmBiMeta?.name ?? "dealer-dealerId-introduce",
    path: introduce7MH3L4JmBiMeta?.path ?? "introduce",
    meta: introduce7MH3L4JmBiMeta || {},
    alias: introduce7MH3L4JmBiMeta?.alias || [],
    redirect: introduce7MH3L4JmBiMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/introduce.vue").then(m => m.default || m)
  },
  {
    name: modelGyb3jEx6qzMeta?.name ?? "dealer-dealerId-model",
    path: modelGyb3jEx6qzMeta?.path ?? "model",
    meta: modelGyb3jEx6qzMeta || {},
    alias: modelGyb3jEx6qzMeta?.alias || [],
    redirect: modelGyb3jEx6qzMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/model.vue").then(m => m.default || m)
  },
  {
    name: index0fHC2M91VfMeta?.name ?? "dealer-dealerId-news",
    path: index0fHC2M91VfMeta?.path ?? "news",
    meta: index0fHC2M91VfMeta || {},
    alias: index0fHC2M91VfMeta?.alias || [],
    redirect: index0fHC2M91VfMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/news/index.vue").then(m => m.default || m)
  },
  {
    name: xjsjlipvFXqqyLMeta?.name ?? "dealer-dealerId-xjsj",
    path: xjsjlipvFXqqyLMeta?.path ?? "xjsj",
    meta: xjsjlipvFXqqyLMeta || {},
    alias: xjsjlipvFXqqyLMeta?.alias || [],
    redirect: xjsjlipvFXqqyLMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId]/xjsj.vue").then(m => m.default || m)
  }
],
    name: _91dealerId_93I9JIX2Z76wMeta?.name ?? undefined,
    meta: _91dealerId_93I9JIX2Z76wMeta || {},
    alias: _91dealerId_93I9JIX2Z76wMeta?.alias || [],
    redirect: _91dealerId_93I9JIX2Z76wMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/dealer/[dealerId].vue").then(m => m.default || m)
  },
  {
    name: seriesfPNvkKnjEMMeta?.name ?? "doArticle_search-components-series",
    path: seriesfPNvkKnjEMMeta?.path ?? "/doArticle_search/components/series",
    meta: seriesfPNvkKnjEMMeta || {},
    alias: seriesfPNvkKnjEMMeta?.alias || [],
    redirect: seriesfPNvkKnjEMMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/doArticle_search/components/series.vue").then(m => m.default || m)
  },
  {
    name: indexfjhOcQPJIvMeta?.name ?? "doArticle_search",
    path: indexfjhOcQPJIvMeta?.path ?? "/doArticle_search",
    meta: indexfjhOcQPJIvMeta || {},
    alias: indexfjhOcQPJIvMeta?.alias || [],
    redirect: indexfjhOcQPJIvMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/doArticle_search/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RP2TB7gc1VMeta?.name ?? "docs-slug",
    path: _91_46_46_46slug_93RP2TB7gc1VMeta?.path ?? "/docs/:slug(.*)*",
    meta: _91_46_46_46slug_93RP2TB7gc1VMeta || {},
    alias: _91_46_46_46slug_93RP2TB7gc1VMeta?.alias || [],
    redirect: _91_46_46_46slug_93RP2TB7gc1VMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/docs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_91paramsString_93_93HXwgVeoUz6Meta?.name ?? "findDealer-paramsString",
    path: _91_91paramsString_93_93HXwgVeoUz6Meta?.path ?? "/findDealer/:paramsString?",
    meta: _91_91paramsString_93_93HXwgVeoUz6Meta || {},
    alias: _91_91paramsString_93_93HXwgVeoUz6Meta?.alias || [],
    redirect: _91_91paramsString_93_93HXwgVeoUz6Meta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/findDealer/[[paramsString]].vue").then(m => m.default || m)
  },
  {
    name: linkUlsvuuvQ5XMeta?.name ?? "help-link",
    path: linkUlsvuuvQ5XMeta?.path ?? "/help/link",
    meta: linkUlsvuuvQ5XMeta || {},
    alias: linkUlsvuuvQ5XMeta?.alias || [],
    redirect: linkUlsvuuvQ5XMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/help/link.vue").then(m => m.default || m)
  },
  {
    name: indexD1liWwjAwSMeta?.name ?? "index",
    path: indexD1liWwjAwSMeta?.path ?? "/",
    meta: indexD1liWwjAwSMeta || {},
    alias: indexD1liWwjAwSMeta?.alias || [],
    redirect: indexD1liWwjAwSMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91paramsString_93_93A8jpe9vR4uMeta?.name ?? "koubei-paramsString",
    path: _91_91paramsString_93_93A8jpe9vR4uMeta?.path ?? "/koubei/:paramsString?",
    meta: _91_91paramsString_93_93A8jpe9vR4uMeta || {},
    alias: _91_91paramsString_93_93A8jpe9vR4uMeta?.alias || [],
    redirect: _91_91paramsString_93_93A8jpe9vR4uMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/koubei/[[paramsString]].vue").then(m => m.default || m)
  },
  {
    name: indexzRMDbYCfisMeta?.name ?? "koubei-publishPraise",
    path: indexzRMDbYCfisMeta?.path ?? "/koubei/publishPraise",
    meta: indexzRMDbYCfisMeta || {},
    alias: indexzRMDbYCfisMeta?.alias || [],
    redirect: indexzRMDbYCfisMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/koubei/publishPraise/index.vue").then(m => m.default || m)
  },
  {
    name: _91articleId_93oNc7MbZmb5Meta?.name ?? "live-articleId",
    path: _91articleId_93oNc7MbZmb5Meta?.path ?? "/live/:articleId()",
    meta: _91articleId_93oNc7MbZmb5Meta || {},
    alias: _91articleId_93oNc7MbZmb5Meta?.alias || [],
    redirect: _91articleId_93oNc7MbZmb5Meta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/live/[articleId].vue").then(m => m.default || m)
  },
  {
    name: indexTGQJtd3plzMeta?.name ?? "live",
    path: indexTGQJtd3plzMeta?.path ?? "/live",
    meta: indexTGQJtd3plzMeta || {},
    alias: indexTGQJtd3plzMeta?.alias || [],
    redirect: indexTGQJtd3plzMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/live/index.vue").then(m => m.default || m)
  },
  {
    name: forget6oDrFCcRc7Meta?.name ?? "login-forget",
    path: forget6oDrFCcRc7Meta?.path ?? "/login/forget",
    meta: forget6oDrFCcRc7Meta || {},
    alias: forget6oDrFCcRc7Meta?.alias || [],
    redirect: forget6oDrFCcRc7Meta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/login/forget.vue").then(m => m.default || m)
  },
  {
    name: indexIaZE2rsRo2Meta?.name ?? "login",
    path: indexIaZE2rsRo2Meta?.path ?? "/login",
    meta: indexIaZE2rsRo2Meta || {},
    alias: indexIaZE2rsRo2Meta?.alias || [],
    redirect: indexIaZE2rsRo2Meta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: registertfRZObhikbMeta?.name ?? "login-register",
    path: registertfRZObhikbMeta?.path ?? "/login/register",
    meta: registertfRZObhikbMeta || {},
    alias: registertfRZObhikbMeta?.alias || [],
    redirect: registertfRZObhikbMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/login/register.vue").then(m => m.default || m)
  },
  {
    name: _91articleId_93yE6BINeKedMeta?.name ?? "news-articleId",
    path: _91articleId_93yE6BINeKedMeta?.path ?? "/news/:articleId()",
    meta: _91articleId_93yE6BINeKedMeta || {},
    alias: _91articleId_93yE6BINeKedMeta?.alias || [],
    redirect: _91articleId_93yE6BINeKedMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/news/[articleId].vue").then(m => m.default || m)
  },
  {
    name: index4JMDg8PppRMeta?.name ?? "news",
    path: index4JMDg8PppRMeta?.path ?? "/news",
    meta: index4JMDg8PppRMeta || {},
    alias: index4JMDg8PppRMeta?.alias || [],
    redirect: index4JMDg8PppRMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: ztChannelYGMG3w51FmMeta?.name ?? "news-ztChannel",
    path: ztChannelYGMG3w51FmMeta?.path ?? "/news/ztChannel",
    meta: ztChannelYGMG3w51FmMeta || {},
    alias: ztChannelYGMG3w51FmMeta?.alias || [],
    redirect: ztChannelYGMG3w51FmMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/news/ztChannel.vue").then(m => m.default || m)
  },
  {
    path: _91seriesId_93VBQc0b4EuZMeta?.path ?? "/series/:seriesId()",
    children: [
  {
    name: _91_91typeId_93_93KGDlPsXreoMeta?.name ?? "series-seriesId-article-typeId",
    path: _91_91typeId_93_93KGDlPsXreoMeta?.path ?? "article/:typeId?",
    meta: _91_91typeId_93_93KGDlPsXreoMeta || {},
    alias: _91_91typeId_93_93KGDlPsXreoMeta?.alias || [],
    redirect: _91_91typeId_93_93KGDlPsXreoMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/article/[[typeId]].vue").then(m => m.default || m)
  },
  {
    name: indexKsOkwUMVqpMeta?.name ?? "series-seriesId",
    path: indexKsOkwUMVqpMeta?.path ?? "",
    meta: indexKsOkwUMVqpMeta || {},
    alias: indexKsOkwUMVqpMeta?.alias || [],
    redirect: indexKsOkwUMVqpMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91modelId_93BJXZTFI5MxMeta?.name ?? "series-seriesId-parameter-modelId",
    path: _91modelId_93BJXZTFI5MxMeta?.path ?? "parameter/:modelId()",
    meta: _91modelId_93BJXZTFI5MxMeta || {},
    alias: _91modelId_93BJXZTFI5MxMeta?.alias || [],
    redirect: _91modelId_93BJXZTFI5MxMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/parameter/[modelId].vue").then(m => m.default || m)
  },
  {
    name: indexhO4CfHSMfFMeta?.name ?? "series-seriesId-parameter",
    path: indexhO4CfHSMfFMeta?.path ?? "parameter",
    meta: indexhO4CfHSMfFMeta || {},
    alias: indexhO4CfHSMfFMeta?.alias || [],
    redirect: indexhO4CfHSMfFMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/parameter/index.vue").then(m => m.default || m)
  },
  {
    name: indexUkYPvfqLygMeta?.name ?? "series-seriesId-praise",
    path: indexUkYPvfqLygMeta?.path ?? "praise",
    meta: indexUkYPvfqLygMeta || {},
    alias: indexUkYPvfqLygMeta?.alias || [],
    redirect: indexUkYPvfqLygMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/praise/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91paramsString_93_930c0CvklBXgMeta?.name ?? "series-seriesId-price-paramsString",
    path: _91_91paramsString_93_930c0CvklBXgMeta?.path ?? "price/:paramsString?",
    meta: _91_91paramsString_93_930c0CvklBXgMeta || {},
    alias: _91_91paramsString_93_930c0CvklBXgMeta?.alias || [],
    redirect: _91_91paramsString_93_930c0CvklBXgMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/price/[[paramsString]].vue").then(m => m.default || m)
  },
  {
    name: _91modelId_93nWAiG6EeieMeta?.name ?? "series-seriesId-price-inquiry-modelId",
    path: _91modelId_93nWAiG6EeieMeta?.path ?? "price/inquiry/:modelId()",
    meta: _91modelId_93nWAiG6EeieMeta || {},
    alias: _91modelId_93nWAiG6EeieMeta?.alias || [],
    redirect: _91modelId_93nWAiG6EeieMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/price/inquiry/[modelId].vue").then(m => m.default || m)
  },
  {
    name: indexeTn9VTJmcaMeta?.name ?? "series-seriesId-video",
    path: indexeTn9VTJmcaMeta?.path ?? "video",
    meta: indexeTn9VTJmcaMeta || {},
    alias: indexeTn9VTJmcaMeta?.alias || [],
    redirect: indexeTn9VTJmcaMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId]/video/index.vue").then(m => m.default || m)
  }
],
    name: _91seriesId_93VBQc0b4EuZMeta?.name ?? undefined,
    meta: _91seriesId_93VBQc0b4EuZMeta || {},
    alias: _91seriesId_93VBQc0b4EuZMeta?.alias || [],
    redirect: _91seriesId_93VBQc0b4EuZMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/series/[seriesId].vue").then(m => m.default || m)
  },
  {
    name: _91articleId_93TpUp9v8Pe3Meta?.name ?? "video-articleId",
    path: _91articleId_93TpUp9v8Pe3Meta?.path ?? "/video/:articleId()",
    meta: _91articleId_93TpUp9v8Pe3Meta || {},
    alias: _91articleId_93TpUp9v8Pe3Meta?.alias || [],
    redirect: _91articleId_93TpUp9v8Pe3Meta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/video/[articleId].vue").then(m => m.default || m)
  },
  {
    name: indexLxngUw5WvMMeta?.name ?? "video",
    path: indexLxngUw5WvMMeta?.path ?? "/video",
    meta: indexLxngUw5WvMMeta || {},
    alias: indexLxngUw5WvMMeta?.alias || [],
    redirect: indexLxngUw5WvMMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/video/index.vue").then(m => m.default || m)
  },
  {
    name: listizirRf9lVaMeta?.name ?? "video-list",
    path: listizirRf9lVaMeta?.path ?? "/video/list",
    meta: listizirRf9lVaMeta || {},
    alias: listizirRf9lVaMeta?.alias || [],
    redirect: listizirRf9lVaMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/video/list.vue").then(m => m.default || m)
  },
  {
    name: _91topicId_93UCE2xQInLBMeta?.name ?? "zhuanti-topicId",
    path: _91topicId_93UCE2xQInLBMeta?.path ?? "/zhuanti/:topicId()",
    meta: _91topicId_93UCE2xQInLBMeta || {},
    alias: _91topicId_93UCE2xQInLBMeta?.alias || [],
    redirect: _91topicId_93UCE2xQInLBMeta?.redirect || undefined,
    component: () => import("D:/word/qcwp-vue/packages/web/src/pages/zhuanti/[topicId].vue").then(m => m.default || m)
  }
]