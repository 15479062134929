// exclude some pinia state from payload
export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.server) {
    nuxtApp.hooks.hook('app:rendered', () => {
      const excludes: string[] = [
        'WEB_AUTH',
      ]
      for (const key of excludes) {
        if (Object.keys(nuxtApp.payload.pinia as any).includes(key))
          delete (nuxtApp.payload.pinia as any)[key]
      }
    })
  }
})
