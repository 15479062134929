import { isArray, isFunction } from '@qcwp/utils'

const errorIds = [
  '669893',
  '669927',
  '670870',
  '669304',
  '669238',
  '670933',
  '669895',
  '669901',
  '669929',
  '669240',
  '669889',
  '669892',
  '670938',
  '669948',
  '718643',
  '670865',
  '669700',
  '718633',
  '718640',
  '669897',
  '669936',
  '669253',
  '669903',
]
/**
 * 兼容旧系统链接
 */
export const transformLink: Record<string, {
  reg: (RegExp | string)[] | RegExp | string
  redirectUrl: string | ((href: string, reg: string | RegExp) => string)
}> = {
  // 网评号首页
  mediaIndex: {
    reg: [/\/article\/mediaIndex(\.jsp|\.html|\/)?$/, /\/wap\/mediaIndex(\.jsp|\.html|\/)?$/],
    redirectUrl: '/article/mediaIndex',
  },
  // 资讯
  news: {
    reg: [/\/news\/aChannel\.html/],
    redirectUrl: '/news',
  },
  // 资讯详情
  newsDetail: {
    reg: [/\/news\/(\d+)\.html/, /\/wapNews\/(\d+).html/],
    redirectUrl: (href, reg) => {
      const id = href.match(reg)?.[1]
      if (id && !isNaN(Number(id)))
        return `/news/${id}`
      else
        return '/news'
    },
  },
  // 行情导购
  hangqing: {
    reg: [/\/news\/hangqing\.html/],
    redirectUrl: '/news/hangqing',
  },
  // 其他资讯栏目：{ 原创:100, 热点关注:38, 测评试驾:7, 厂商动态:6, 新车上市:106, 行业动态:44, 汽车文化:42, 汽车召回:10, 汽车用品:4 }
  articleType: {
    reg: [/\/article\/articleType\/(\d+)\.html/, /\/wap\/aList\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const typeId = href.match(reg)?.[1]
      if (typeId && !isNaN(Number(typeId)))
        return `/article/articleType/${typeId}`
      else
        return '/news'
    },
  },
  // 网评号文章列表
  articleTypeMedia: {
    reg: [/\/article\/zmtChannel\.html/],
    redirectUrl: '/article/articleType/zmtChannel',
  },
  articleTypeAll: {
    reg: [/\/article\/articleTypeAll(\.jsp|\.html|\/)?$/],
    redirectUrl: '/news',
  },
  // 媒体详情
  mediaDetail: {
    // old: `/article/authorArticle/0_108.html` （startPage_mediaUserId）
    reg: [/\/article\/authorArticle\/(.*)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[1]
      const id = param?.split('_')?.[1] || param
      if (param)
        return `/article/authorArticle/${id}`
      else
        return '/404'
    },
  },
  // 专题首页 `/wap/spe/sptj.html`
  topic: {
    reg: [/\/news\/ztChannel\.html/, /\/wap\/spe\/sptj\.html/],
    redirectUrl: '/news/ztChannel',
  },
  // 专题详情 `/wap/spe/carAutoShowTopic.jsp?id=23`
  topicDetail: {
    reg: [/\/zhuanti\/(\d+).html/, /\/wap\/spe\/carAutoShowTopic\.jsp\?id=(\d+)/],
    redirectUrl: (href, reg) => {
      const id = href.match(reg)?.[1]
      if (id && !isNaN(Number(id)))
        return `/zhuanti/${id}`
      else
        return '/news/ztChannel'
    },
  },
  // 汽车大全/按品牌找车
  zhaoche: {
    reg: [/\/car\/zhaoche\.html/, /\/wap\/brand\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[1]
      if (param)
        return `/car/zhaoche/${param}`
      else
        return '/car/zhaoche'
    },
  },
  // 找车/综合筛选 `/car/zhaocheZh/0_0-0_0_0_0_0_0_0.html` `/wap/anTiaojianTotal/0_0-0_0_0_0_0_0_0.html`
  // TODO 目前和新系统对应不上
  zhaocheZh: {
    reg: [
      /\/car\/zhaocheZh\/(.*)\.html/,
      /\/wap\/anTiaojianTotal\/(.*)\.html/,
      /\/wap\/anTiaojian\/(.*)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[1]
      if (param)
        return `/car/zhaocheZh/${param}`
      else
        return '/car/zhaocheZh'
    },
  },
  // 车系详情首页 `/wap/seriesIndex/1353.html`
  seriesIndex: {
    reg: [/\/(series|wap)\/seriesIndex\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[2]
      if (param)
        return `/series/${param}/`
      else
        return '/404'
    },
  },
  // 车系详情参数
  seriesParameter: {
    reg: [/\/(series|wap)\/seriesParameter\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[2]
      if (param)
        return `/series/${param}/parameter`
      else
        return '/404'
    },
  },
  // 车系详情油耗 TODO
  seriesOil: {
    reg: [/\/(series|wap)\/seriesOil\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[2]
      if (param)
        return `/series/seriesOil/${param}`
      else
        return '/404'
    },
  },
  // 车系详情报价
  seriesPrice: {
    reg: [/\/(series|wap)\/seriesPrice\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[2]
      if (param)
        return `/series/${param}/price`
      else
        return '/404'
    },
  },
  // 车系详情口碑
  seriesPraise: {
    reg: [/\/(series|wap)\/seriesPraise\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[2]
      if (param)
        return `/series/${param}/praise`
      else
        return '/404'
    },
  },
  // 车系车型详情口碑
  carTypePraise: {
    reg: [/\/(series|wap)\/carTypePraise\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[2]
      if (param)
        return `/series/carTypePraise/${param}`
      else
        return '/404'
    },
  },
  // 车系详情资讯
  seriesArticle: {
    reg: [/\/series\/seriesArticle\/(\d+)\.html/, /\/wap\/seriesArticle\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[1]
      if (param)
        return `/series/${param}/article`
      else
        return '/404'
    },
  },
  // 车系详情对比
  duibi: {
    reg: [/\/car\/duibi(\.jsp|\.html|\/)?$/],
    redirectUrl: '/car/duibi',
  },

  // 口碑
  koubei: {
    reg: [/\/koubei\/index\.html/, /\/wap\/koubei\/?$/],
    redirectUrl: '/koubei',
  },
  koubeiParam: {
    reg: [/\/koubei\/(\w+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[1]
      if (param)
        return `/koubei/${param}`
      else
        return '/404'
    },
  },

  // 图库 /car/carTuku.html
  carTuku: {
    reg: [/\/(car|wap)\/carTuku\.html/],
    redirectUrl: '/car/carTuku',
  },
  // 车系图库 /car/seriesImg/1.html
  seriesImg: {
    reg: [/\/(car|wap)\/seriesImg\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[2]
      if (param)
        return `/car/seriesImg/${param}`
      else
        return '/404'
    },
  },
  seriesImg2: {
    reg: [/\/(car|wap)\/seriesImg\/(\d+_\d+_\d+_\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[2]
      if (param)
        return `/car/carTypeImg/${param}`
      else
        return '/404'
    },
  },
  // 车系图库 /car/carTypeImg/1_25098.html （seriesId_modelId_{1:车身外观,2:中控方向盘,3:车厢座椅}_pageSize） `/wap/carTypeImg/1224_26532_0.html`（seriesId_modelId_pageSize）pageSize基本固定0
  carTypeImg: {
    reg: [/\/(car|wap)\/carTypeImg\/(.*)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[2]
      if (param)
        return `/car/carTypeImg/${param}`
      else
        return '/404'
    },
  },
  // 图集
  atlas: {
    reg: [/\/atlas\/(.*)\.html/],
    redirectUrl: () => {
      return '/500'
    },
  },
  // 图集
  imgView: {
    reg: [/\/imgView\/(\w+)\.html/],
    redirectUrl: (href, reg) => {
      return '/500'
    },
  },

  // 经销商 `/findDealer/index.html`
  findDealer: {
    reg: [/\/findDealer\/index\.html/, /\/wap\/dealer\/index\.html/],
    redirectUrl: '/findDealer/index',
  },
  // 经销商详情 `/dealer/470.html`
  dealer: {
    reg: [/\/dealer\/(\d+)\.html/, /\/wapDealer\/(\d+).html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[1]
      if (param)
        return `/dealer/${param}`
      else
        return '/404'
    },
  },
  // 经销商详情车型报价 `/dealer/cpl/470.html`
  dealerCpl: {
    reg: [/\/dealer\/cpl\/(\d+)\.html/, /\/wapDealerCpl\/(\d+).html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[1]
      if (param)
        return `/dealer/${param}/model`
      else
        return '/404'
    },
  },
  // 经销商详情促销信息 `/dealerCuxiaos/470.html` `/wapDealerCxs/1.html`
  dealerCuxiaos: {
    reg: [/\/dealerCuxiaos\/(\d+)\.html/, /\/wapDealerCxs\/(\d+).html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[1]
      if (param)
        return `/dealer/${param}/cuxiao`
      else
        return '/404'
    },
  },
  // 经销商详情新闻资讯 `/dealerNewsList/470.html` `/dealerNewsList/470_1_0.html`
  dealerNewsList: {
    reg: [/\/dealerNewsList\/(\d+)_?(.*)?\.html/],
    redirectUrl: (href, reg) => {
      const regResult = href.match(reg)
      if (regResult) {
        const id = regResult?.[1]
        const param = regResult?.[2] || ''
        if (id && param)
          return `/dealer/${id}/news/${param}`
        else if (id)
          return `/dealer/${id}/news`
      }
      return '/404'
    },
  },
  // 经销商详情公司介绍 `/dealerIntroduce/470.html`
  dealerIntroduce: {
    reg: [/\/(dealerIntroduce|wapDealerIntroduce)\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[2]
      if (param)
        return `/dealer/${param}/introduce`
      else
        return '/404'
    },
  },
  // 经销商详情新闻资讯详情 `/dealerNews/566307.html` /wap/dealerArticleDetail/150548.html
  dealerNews: {
    reg: [/\/dealerNews\/(\d+)\.html/, /\/wap\/dealerArticleDetail\/(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[1]
      if (param)
        return `/news/${param}`
      else
        return '/404'
    },
  },
  // 经销商详情车系详情 `/dealer/dcs/470_1400.html`
  dealerSeries: {
    reg: [/\/dealer\/dcs\/(\d+)_(\d+)\.html/],
    redirectUrl: (href, reg) => {
      const regResult = href.match(reg)
      const id = regResult?.[1]
      const seriesId = regResult?.[2] || ''
      if (id && seriesId)
        return `/dealer/${id}/dealerCarDetail/${seriesId}`
      else
        return '/404'
    },
  },
  // TODO 经销商详情车型详情 `/dealerCarTypeDetail/26.html` 貌似不是车型Id /wapDealerCarTypeDetail/129225.html
  dealerCarTypeDetail: {
    reg: [/\/dealerCarTypeDetail\/(\d+)\.html/, /\/wapDealerCarTypeDetail\/(\d+).html/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[1]
      if (param)
        return `/dealerCarTypeDetail/${param}`
      else
        return '/404'
    },
  },
  dealerXjsj: {
    reg: [/xjsj\.(jsp|html)/],
    redirectUrl: (href, reg) => {
      return '/404'
    },
  },
  search: {
    reg: [/\/doArticle_search(\w+)/, /\/wap\/doArticle_wapSearch(\w+)/],
    redirectUrl: (href, reg) => {
      const param = href.match(reg)?.[1]
      if (param)
        return `/doArticle_search${param}`
      else
        return '/404'
    },
  },
  // 首页
  home: {
    reg: [/\/index(\.jsp|\.html|\/)?$/, /\/wap(\.jsp|\.html|\/)?$/],
    redirectUrl: '/',
  },
  register: {
    reg: [/\/media\/pages\/reg\/reg/],
    redirectUrl: '/login/register',
  },
  about: {
    reg: [/\/webHelp\/about\.html/],
    redirectUrl: '/docs/about',
  },
  serviceStatement: {
    reg: [/\/webHelp\/serviceStatement\.html/],
    redirectUrl: '/docs/statement',
  },
  firendlink: {
    reg: [/\/webHelp\/firendlink\.html/],
    redirectUrl: '/help/link',
  },
  advertService: {
    reg: [/\/webHelp\/advertService\.html/],
    redirectUrl: '/docs/ad',
  },
}
/**
 *  判断链接是否时旧系统链接
 */
export function checkLinkIsOld(href: string, regexps: (RegExp | string)[] | RegExp | string): string | RegExp | false {
  if (isArray(regexps)) {
    for (const reg of regexps) {
      const result = checkLinkIsOld(href, reg)
      if (result)
        return result
    }
  }
  else {
    return href.match(regexps) ? regexps : false
  }
  return false
}
export function getRedirectUrl(href: string) {
  for (const key of Object.keys(transformLink)) {
    const item = transformLink[key]
    const reg = item.reg

    const result = checkLinkIsOld(href, reg)
    if (result)
      return isFunction(item.redirectUrl) ? item.redirectUrl(href, result) : item.redirectUrl
  }
}

export default defineNuxtPlugin(() => {
  const isDev = useCookie('isDev')

  addRouteMiddleware('global-oldMiddleware', (_to, _from) => {
    const { href, matched } = _to as any
    if (_to.query.isDev)
      isDev.value = '1'

    // if (!_to.query.dev)
    //   return navigateTo('/500.html', { redirectCode: 301, external: true })
    let url = '/404'
    if (href.match(/news\/(\d+)/)) {
      const id = href.match(/news\/(\d+)/)[1]
      if (errorIds.includes(id))
        return navigateTo('/404', { redirectCode: 404, external: false })
    }
    if (href !== '/500.html' && (matched[0].path === '/:slug(.*)*' || href.match(/(\.html|\.jsp)/))) {
      const redirectUrl = getRedirectUrl(href)
      if (redirectUrl)
        url = redirectUrl

      return navigateTo(url, { redirectCode: url === '/404' ? 404 : 301, external: true })
    }

    // if ((href.match(/\/car/) || href.match(/\/series/) || href.match(/\/dealer/) || href.match(/\/koubei/)) && !isDev.value)
    //   return navigateTo('/500', { redirectCode: 301, external: false })
  }, { global: true })
})
