import type { Directive } from 'vue'
import { isArray } from '@qcwp/utils'

export default defineNuxtPlugin((nuxtApp) => {
  const vueApp = nuxtApp.vueApp
  if (!vueApp._context.directives?.affix)
    vueApp.directive('affix', affixDirective())
})

function affixDirective(): Directive<HTMLElement, string | number> {
  let affixEl: HTMLElement | null = null
  let affixContainerEl: HTMLElement | null = null
  let affixTop: number | string[] = 0
  let stop: (() => void) | undefined
  return {
    mounted(el, binding) {
      if (!el)
        return
      affixEl = el
      affixContainerEl = el.parentElement
      affixTop = (typeof binding.value === 'number' || !binding.value)
        ? (binding.value || 0) as number
        : binding.value.split(' ').map((item: string) => item.trim())

      if (isArray(affixTop)) {
        const classList = Array.from(affixEl.classList)
        for (let i = 0; i < classList.length; i++) {
          const className = classList[i]
          const index = affixTop.indexOf(className)
          if (index > -1)
            affixTop.splice(index, 1)
        }
      }
      const targetIsVisible = useElementVisibility(affixContainerEl)
      stop = watch(targetIsVisible, (isVisible) => {
        // console.log('isVisible', window.scrollY, affixEl?.offsetTop, [affixEl])
        if (!isVisible && window.scrollY > (affixEl?.offsetTop || 0)) {
          if (typeof affixTop === 'number') {
            affixEl!.style.position = 'fixed'
            affixEl!.style.top = `${affixTop}px`
          }
          else if (affixTop) {
            affixTop.forEach(className => affixEl!.classList.add(className))
          }
        }
        else {
          if (typeof affixTop === 'number') {
            affixEl!.style.position = ''
            affixEl!.style.top = ''
          }
          else if (affixTop) {
            affixTop.forEach(className => affixEl!.classList.remove(className))
          }
        }
      })
    },
    getSSRProps() {
      return {}
    },
    beforeUnmount() {
      affixEl = null
      affixContainerEl = null
      affixTop = undefined!
      stop?.()
    },
  }
}
