import { darkTheme, lightTheme } from 'naive-ui'
import type { GlobalTheme, GlobalThemeOverrides } from 'naive-ui'
import { darkThemeThemeOverrides, lightThemeThemeOverrides } from './naive'

export const breakpoints = (p = false) => {
  const points = {
    'mn': 400,
    'sm': 640,
    'md': 768,
    'tablet': 920,
    'lg': 1024,
    'xl': 1280,
    '2xl': 1536,
    'mobile': 400,
  }
  if (p) {
    const newPoints: Record<string, string> = {}
    for (const key in points)
      newPoints[key] = `${points[key as keyof typeof points]}px`
    return newPoints
  }
  return points
}
export function useTheme() {
  // const theme =  useColorMode({ initialValue: 'light' })
  const theme = ref('light')

  const naiveTheme = computed<GlobalTheme | null>(() => theme.value === 'dark' ? darkTheme : lightTheme)
  const naiveThemeOverrides = computed<GlobalThemeOverrides | null>(() => theme.value === 'dark'
    ? darkThemeThemeOverrides
    : lightThemeThemeOverrides,
  )

  return {
    naiveTheme,
    naiveThemeOverrides,
  }
}
