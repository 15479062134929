<script setup lang='ts'>
import { loginPath } from '~~/../common/src'

const props = withDefaults(defineProps<{
  modelValue: boolean
  redirect?: string
}>(), {
  modelValue: false,
  redirect: '/',
})

const emits = defineEmits({
  'update:modelValue': (_value: boolean) => true,
})
onDeactivated(() => {
  emits('update:modelValue', false)
})
</script>

<template>
  <n-modal
    mask-closable
    :trap-focus="false"
    :show="props.modelValue"
    @update:show="(v) => emits('update:modelValue', v)"
  >
    <div class="w-80 bg-white rounded-md pt-3xl pb-xl relative" md="py-3xl w-90 px-lg">
      <i
        class="i-ic:baseline-close absolute text-lg top-sm right-sm cursor-pointer"
        @click="emits('update:modelValue', false)"
      />
      <div class="font-bold text-center text-5 mb-sm">
        手机快捷登录
      </div>
      <page-login-phone-login />
      <div class="flex justify-end md:hover:text-blue-5 px-lg">
        <NuxtLink :to="loginPath({ type: 'account', redirect })" class="flex items-center" @click="emits('update:modelValue', false)">
          <span>更多登录方式</span>
          <i class="text-4.5 i-ic:sharp-keyboard-double-arrow-right" />
        </NuxtLink>
      </div>
    </div>
  </n-modal>
</template>

<style lang='scss' scoped>

</style>
