import { baseRule, phoneBaseRule } from '~~/src/common/rules'
import { useAuth } from '~~/src/store'
import type { PhoneForm } from '~~/src/store'

export function userPhoneLogin() {
  const authStore = useAuth()
  const phoneForm = ref<PhoneForm>({
    loginAccountOrPhone: '',
    verifCode: '',
  })

  const phoneRules = {
    loginAccountOrPhone: [baseRule('请输入手机号'), phoneBaseRule()],
    verifCode: [baseRule('请输入验证码')],
  }

  function phoneLogin(e: PhoneForm) {
    authStore.phoneLogin(e)
  }

  return {
    phoneForm,
    phoneRules,
    phoneLogin,
  }
}
