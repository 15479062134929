<script setup lang='ts'>
const props = withDefaults(defineProps<{
  modelValue?: string
  disabled?: boolean
  autofocus?: boolean
}>(), {
  modelValue: '',
  disabled: false,
  autofocus: false,
})

const emits = defineEmits({
  'update:modelValue': (value?: string) => true,
})
const textareaRef = ref<HTMLTextAreaElement | null>(null)
const stop = watch(textareaRef, () => {
  if (textareaRef.value && props.autofocus)
    textareaRef.value?.focus?.()
})
function changeHandler(event: Event) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  emits('update:modelValue', event.target?.value)
}

onUnmounted(() => {
  stop()
})
</script>

<template>
  <div>
    <div class="relative text-center">
      <p class="w-full outline-none break-words min-h-6">
        {{ props.modelValue }}
      </p>
      <textarea
        v-if="!props.disabled"
        ref="textareaRef"
        focus:autofocus="props.autofocus"
        type="text"
        :value="props.modelValue"
        class="text-center resize-none absolute absolute-0 break-words"
        @input="changeHandler"
      />
    </div>
  </div>
</template>

<style lang='scss' scoped>

</style>
