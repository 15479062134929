import { isClient } from '@qcwp/utils'
import NProgress from 'nprogress'
import type { H3Event } from 'h3'
import { adLoadHandle } from '~~/src/store'
import type { useAbort } from '~~/src/server/request/abort'
import type { NuxtApp } from '#app'

function abortRequest(nuxtApp: NuxtApp) {
  const event = nuxtApp.ssrContext?.event as H3Event
  const request = event?.node?.req
  if (!request)
    return

  // console.log(request)
  const closeHandler = () => {
    const { pendingRequest, abortPrePageKeys } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
    // console.log('Server Close', request.url)
    // if (pendingRequest.size > 0) {
    //   pendingRequest.forEach((value: any, key: any) => {
    //     (value.controller as AbortController).abort(new DOMException('页面切换终止请求', 'AbortPrePageError'))
    //     abortPrePageKeys.push(key)
    //   })
    //   pendingRequest.clear()
    // }
    // request.destroy()
  }
  const endHandler = () => {
    // console.log('Server end')
  }
  request.once('close', closeHandler)
  request.once('end', endHandler)
}

// https://nuxt.com/docs/api/advanced/hooks
export default defineNuxtPlugin((nuxtApp) => {
  /**
   * Environment Server & Client
   */
  // app:created
  const startTimestamp = performance.now()
  nuxtApp.hook('app:created', () => {
    // console.log('<<app:created>>')
    abortRequest(nuxtApp as NuxtApp)
  })
  // app:error
  nuxtApp.hook('app:error', (e) => {
    // console.log('<<app:error>>', e)
  })
  // app:error:cleared
  nuxtApp.hook('app:error:cleared', () => {
    // console.log('<<app:error>>:cleared')
  })
  // app:data:refresh
  nuxtApp.hook('app:data:refresh', () => {
    // console.log('<<app:data>>:refresh')
  })
  // vue:setup
  nuxtApp.hook('vue:setup', () => {
    // console.log('<<vue:setup>>')
  })
  // vue:error
  nuxtApp.hook('vue:error', () => {
    // console.log('<<vue:error>>')
  })

  /**
   * Environment Server
   */
  // app:rendered
  nuxtApp.hook('app:rendered', (event) => {
    // 返回的是一个未拼接的html对象
    // console.log('<<app:rendered>>', performance.now() - startTimestamp)
  })
  // app:redirected
  nuxtApp.hook('app:redirected', () => {
    // console.log('<<app:redirected>>')
  })

  // app:beforeMount
  nuxtApp.hook('app:beforeMount', () => {
    // console.log('<<app:beforeMount>>')
  })
  // app:mounted
  nuxtApp.hook('app:mounted', () => {
    // console.log('<<app:mounted>>')
  })
  // app:suspense:resolve
  nuxtApp.hook('app:suspense:resolve', () => {
    // console.log('<<app:suspense:resolve>>')
    // load ad in client， 当页面水合结束后，加载广告避免影响页面水合。
    if (isClient)
      adLoadHandle()
  })

  function isExtend() {
    const route = useRoute()
    const { name, params } = route
    if (!name)
      return false
    switch (name) {
      case 'koubei-paramsString':
        return !!unref(params).paramsString
      default:
        return false
    }
  }
  // page:start
  nuxtApp.hook('page:start', () => {
    // console.log('<<page:start>>')
    const platform = RENDER_PLATFORM()
    const { setState } = useSetState()
    if (platform.value === 'client' && !isExtend()) {
      setState(PAGE_STATE.LOADING)
      NProgress.start()
    }
  })
  // page:finish
  nuxtApp.hook('page:finish', () => {
    // console.log('<<page:finish>>')
    const platform = RENDER_PLATFORM() // 渲染平台
    const loadState = RENDER_LOAD_STATE()
    const { setState } = useSetState()

    if (isClient) {
      // 将渲染平台修改为client
      if (platform.value === 'server')
        platform.value = 'client'

      NProgress.done()
    }
    // 如果不是ERROR，则修改成加载完成
    loadState.value !== PAGE_STATE.ERROR && setState(PAGE_STATE.LOADED)
  })
  // page:transition:finish
  nuxtApp.hook('page:transition:finish', () => {
    // console.log('<<page:transition:finish>>')
  })
})
