<script setup lang='ts'>
import { articleDetailPath, imageTransform } from '@qcwp/common'
import type { DailyEssenceArticleDataRefVO } from '~~/src/server/modules/article/type'
import { useDailyStore } from '~~/src/store'
const props = withDefaults(defineProps<{
  item: DailyEssenceArticleDataRefVO
  tools?: boolean
}>(), {
  tools: true,
})
const dailyStore = useDailyStore()
const imageWidth = 750
const imageHeight = Math.floor(imageWidth * 0.5625)
</script>

<template>
  <div>
    <layout-daily-tool-img
      :tools="props.tools"
      :is-show-arrow="false"
      :src="imageTransform(item.dataRefImgs[0], { width: imageWidth, height: imageHeight, format: 'webp' })"
    />
    <layout-daily-media :data-ref-type="item.dataRefType" :media-list="item.mediaUserVOList" :look-count="Number(item.look)" />
    <div class="flex items-center justify-center mt-3 gap-3">
      <nuxt-link
        :to="articleDetailPath(item.articleType, {
          id: item.dataRefId,
          isSwiper: item.essenceColumn === '1',
        })"
        class="px-4 py-1 rounded-md bg-blue-5 text-white"
      >
        查看详情
      </nuxt-link>
      <button
        class="px-4 py-1 rounded-md bg-red-5 text-white"
        @click="dailyStore.removeHandler({
          dataRefId: item.dataRefId,
          articleType: item.articleType,
          essenceColumn: item.essenceColumn,
        })"
      >
        删除
      </button>
    </div>
  </div>
</template>

<style lang='scss' scoped>

</style>
