import { isClient } from '@qcwp/utils'
import { siteAccess } from '~~/src/server/modules/base'
/**
 * 访问统计
 */
export function useAddAccess() {
  if (!isClient)
    return
  try {
    siteAccess()
  }
  catch (error) {
    console.error(error)
  }
}
