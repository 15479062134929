<script setup lang='ts'>
import { ARTICLE_TYPE, USER_TYPE, dailyPath } from '@qcwp/common'
import { isClient } from '@qcwp/utils'
import { useAuth, useDailyStore } from '~~/src/store'

import { getTestDriveText } from '~~/src/pages/article/hooks/daily/shared'

const route = useRoute()
const authStore = useAuth()
const { USER_MEDIA_TYPE } = storeToRefs(authStore)

const dailyStore = useDailyStore()
const {
  editorTime,
  show,
  loadStatus,
  currentEssence,
  essenceId,
  todayDailyCreated,

  currentArticle,
} = storeToRefs(dailyStore)
const canAdd = computed(() => {
  return ['news-articleId', 'video-articleId', 'live-articleId'].includes(route.name as string)
    && currentArticle
    && !dailyStore.checkInclude()
})

const { isMobileOrTablet } = useDevice()
const placement = isMobileOrTablet ? 'bottom' : 'right'

let stop = watch(() => route.path, () => {
  show.value = false
})
onBeforeUnmount(() => {
  stop()
  stop = null!
})

function visibleHandler() {
  dailyStore.toggleShow()
}

/************************************************************/
const essenceArticles = computed(() => currentEssence.value?.articles?.filter(v => v.essenceColumn === '1') || [])
const guideArticles = computed(() => currentEssence.value?.articles?.filter(v => v.essenceColumn === '2') || [])
const testDriveArticles = computed(() => currentEssence.value?.articles?.filter(v => v.essenceColumn === '3') || [])
const testDriveArticleTitle = computed(() => {
  return getTestDriveText(testDriveArticles.value)
})
/************************************************************/

function initHandler() {
  if (!isClient)
    return

  if (USER_MEDIA_TYPE.value === USER_TYPE.SELF)
    dailyStore.initHandler()
}
onDeactivated(() => {
  show.value = false
})
initHandler()
</script>

<template>
  <div>
    <div class="daily-button" @click="visibleHandler">
      <i class="i-dashicons:admin-customizer" />
    </div>
    <n-drawer v-model:show="show" :width="500" height="85vh" :placement="placement">
      <n-drawer-content title="日报编辑" closable>
        <div v-if="USER_MEDIA_TYPE !== USER_TYPE.SELF" class="h-full font-bold text-5 text-slate-5 grid place-content-center">
          无编辑权限
        </div>
        <div v-else class="flex flex-col pb-20">
          <div class="flex items-center justify-between mb-3 flex-shrink-0">
            <div>日期：{{ editorTime }}</div>
            <div class="flex items-center">
              <nuxt-link
                :to="dailyPath(essenceId)"
                class="px-4 py-1 rounded-md bg-blue-5 text-white mr-3"
                @click="() => route.name === 'article-daily' ? (show = false) : ''"
              >
                查看详情
              </nuxt-link>
              <button class="bg-red-5 text-white rounded-md px-3 py-1" @click="dailyStore.clearHandler(true, true)">
                清空缓存
              </button>
            </div>
          </div>
          <div v-if="loadStatus === 'INIT'" class="grid place-content-center mt-3 h-full">
            加载中...
          </div>
          <div v-else-if="loadStatus === 'FAIL'" class="grid place-content-center">
            <div>数据加载失败</div>
            <button class="bg-blue-5 text-white px-5 py-2 rounded-md mt-5" @click="dailyStore.clearHandler(true, true)">
              重新加载
            </button>
          </div>
          <div v-else-if="loadStatus === 'NULL' || !todayDailyCreated" class="grid place-content-center pt-10">
            <div>还未创建相关日报</div>
            <button class="bg-green-5 text-white px-5 py-2 rounded-md mt-5" @click="dailyStore.createHandler()">
              立即创建
            </button>
          </div>
          <div v-else class="flex-grow">
            <div class="mb-sm">
              <h4 class="title">
                今日精华
              </h4>
              <div v-if="essenceArticles.length" class="mb-3">
                <layout-daily-big-item
                  v-for="v in essenceArticles" :key="v.dataRefId"
                  :tools="false"
                  :item="v"
                />
              </div>
              <button
                v-if="canAdd && essenceArticles.length === 0"
                class="bg-green text-white w-full py-2 text-center rounded-md"
                @click="dailyStore.addHandler('1')"
              >
                添加当前文章到精华
              </button>
              <div v-else-if="essenceArticles.length === 0" class="text-center text-slate-6">
                请选择一篇内容进行访问
              </div>
            </div>
            <div class="mb-3">
              <h4 class="title">
                今日导读
              </h4>
              <div v-if="guideArticles.length" class="divide-y-2 divide-slate-2 divide-dashed mb-3">
                <layout-daily-guide-item
                  v-for="v in guideArticles" :key="v.dataRefId"
                  v-model:title="v.dataRefTitle"
                  :item="v"
                  class="py-3 first:pt-0 last:pb-0"
                />
              </div>
              <button
                v-if="canAdd && currentArticle?.articleType === ARTICLE_TYPE.PICTURE"
                class="bg-green text-white w-full py-2 text-center rounded-md"
                @click="dailyStore.addHandler('2')"
              >
                添加当前文章到导读
              </button>
              <div v-else-if="currentArticle?.articleType !== ARTICLE_TYPE.PICTURE || !guideArticles.length" class="text-center text-slate-6">
                请选择一篇图文内容进行访问
              </div>
            </div>
            <div>
              <h4 class="title">
                {{ testDriveArticleTitle }}
              </h4>
              <div v-if="testDriveArticles.length" class="divide-y-2 divide-slate-2 divide-dashed mb-3">
                <layout-daily-big-item
                  v-for="v in testDriveArticles" :key="v.dataRefId"
                  :tools="false"
                  class="py-3 first:pt-0 last:pb-0"
                  :item="v"
                />
              </div>
              <button
                v-if="canAdd"
                class="bg-green text-white w-full py-2 text-center rounded-md"
                @click="dailyStore.addHandler('3')"
              >
                添加当前文章当前栏目
              </button>
              <div v-else-if="!testDriveArticles.length" class="text-center text-slate-6">
                请选择一篇图文/视频/直播内容进行访问
              </div>
            </div>
          </div>
        </div>
      </n-drawer-content>
    </n-drawer>
  </div>
</template>

<style lang='scss' scoped>
.daily-button {
  --uno: bg-white shadow-lg border-1 border-gray-1 rounded-full flex flex-col items-center justify-center w-10 h-10 cursor-pointer;
  --uno: fixed z-99 bottom-45 right-3;
  --uno: md-w-15 md-h-15 md-text-4.5 md-right-5 md-bottom-45;
}

.title {
  --uno: text-lg font-bold mb-sm text-center bg-slate-7 text-white rounded-sm py-1;
}
</style>
